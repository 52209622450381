<template>
	<el-row :gutter="160">
        <el-col :span="24" style="text-align: left;">
            <el-dropdown @command="onSelectGroup">
                <el-button type="success" style="width:144px;">
                    {{curGroupLabel}}<i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item v-for="item in serverGroups" :key="item.value" :command="item" icon="el-icon-coin">
                        {{item.label}}
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
			<el-button type="primary" icon="el-icon-plus" @click="onClickAdd" style="margin-left: 8px;">添加新通知</el-button>
		</el-col>
		<el-col :span="24">
			<el-table ref="multipleTable" :data="paramList" :height="tableHeight" border style="width: 100%" :row-class-name="tableRowClassName"
			:cell-style="{padding:4+'px'}" class="serverlist">
				<el-table-column prop="id" label="id" width="60"> </el-table-column>
                <el-table-column prop="groupname" label="区服列表" show-overflow-tooltip></el-table-column> 
                <el-table-column prop="servername" label="区服" show-overflow-tooltip></el-table-column> 
				<el-table-column prop="interval" label="间隔时间(秒)" show-overflow-tooltip></el-table-column>  
                <el-table-column prop="type" label="类型" show-overflow-tooltip></el-table-column> 
                <el-table-column prop="priority" label="优先级" show-overflow-tooltip></el-table-column> 
				<el-table-column prop="begin_time" label="开始时间" show-overflow-tooltip></el-table-column>
				<el-table-column prop="end_time" label="结束时间" show-overflow-tooltip></el-table-column>                                 
				<el-table-column prop="content" label="通知内容" show-overflow-tooltip></el-table-column>              
                <el-table-column label="状态" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span v-if="scope.row.state===0">待发</span>
                        <span v-if="scope.row.state===1">提交</span>
                        <span v-if="scope.row.state===2">成功</span>
                        <span v-if="scope.row.state===3" style="color: #FF0000;">失败</span>
                        <span v-if="scope.row.state===4" style="color: #FF0000;">删除</span>
                    </template>
                </el-table-column>
				<el-table-column prop="create_time" label="创建时间" show-overflow-tooltip></el-table-column>
                <el-table-column label="操作" width="200">
                    <template slot-scope="scope">
                        <el-button @click.native.prevent="onClickEditNotice(scope.row)" type="primary" icon="el-icon-edit" size="small" :disabled="scope.row.state>3">修改</el-button>
                        <el-button @click.native.prevent="onClickDelNotice(scope.row)" type="danger" icon="el-icon-delete" size="small" :disabled="scope.row.state>3">删除</el-button>
                    </template>
                </el-table-column>
			</el-table>
		</el-col>
		<!-- ========弹框BEGIN====== -->
		<el-dialog :title="formTitle" :visible.sync="dialogForm" width="800px" :close-on-click-modal="false" center class="formparams">
			<el-form :model="form">
                <el-form-item label="区服列表" :label-width="formLabelWidth">
                    <el-select v-model="form.groupid" placeholder="选择分组(不选则是全部)" value-key="value" @change="onFormSelectGroup" style="width: 100%;" :disabled="formTitle=='修改通知'">
                        <el-option v-for="item in selectGroups" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="区服选择" :label-width="formLabelWidth">
                    <el-select v-model="form.serverid" placeholder="指定区服(不选则是全部)" value-key="value" @change="onFormSelectServer" :multiple="true" style="width: 100%;" :disabled="formTitle=='修改通知'">
                        <el-option v-for="item in formServers" :key="item.id" :label="item.name+'-'+item.title" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>            
                <el-form-item label="通知时间范围" :label-width="formLabelWidth">
                    <el-date-picker
                      v-model="form.notice_time"
                      type="datetimerange"
                      range-separator="至"
                      start-placeholder="开始"
                      end-placeholder="结束"
                      @blur="onFormEdited"
                      style="width: 100%;">
                    </el-date-picker>      
                </el-form-item>   
                <el-form-item label="间隔时间(秒)" :label-width="formLabelWidth">
                    <el-input v-model="form.interval" autocomplete="off" @change="onFormEdited"></el-input>
                </el-form-item> 
                <el-form-item label="类型" :label-width="formLabelWidth">
                    <el-input v-model="form.type" autocomplete="off" @change="onFormEdited"></el-input>
                </el-form-item>    
                <el-form-item label="优先级" :label-width="formLabelWidth">
                    <el-input v-model="form.priority" autocomplete="off" @change="onFormEdited"></el-input>
                </el-form-item>                                                 
                <el-form-item label="通知内容" :label-width="formLabelWidth">
                    <el-input v-model="form.content" autocomplete="off" type="textarea" :rows="4" @change="onFormEdited"></el-input>          
                </el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" icon="el-icon-check" @click="onAddNotice" :disabled="noneChanged">确 定</el-button>
				<el-button icon="el-icon-close" @click="dialogForm = false">取 消</el-button>
			</div>
		</el-dialog>
		<!-- ========弹框END====== -->
	</el-row>
</template>

<script>
	import {
		mapGetters
	} from 'vuex'
    import tool from '../../tool.js'

	export default {
		props: ['pkgtype'],
		data() {
			return {
				paramList: [],
                formTitle: '',
				dialogForm: false,
                form: {
                    id: '',
                    groupid:'',
                    serverid: [],
                    notice_time:[],
                    interval: '',
                    type: '',
                    priority: '',
                    content: '',
                },
                formServers: [],
				noneChanged: true,
                formLabelWidth: '100px',
                serverGroups:[],
                curGroupId: -1, // 当前选择分组(默认全部)
                curGroupLabel: "全部区服列表", // 当前选择分组名,
                tableHeight: document.documentElement.clientHeight-136,			
            }
		},
		created: function() {
			this.refreshNoticeList()
            this.refreshGroupList()
		},
		computed: {
			...mapGetters(
				['gameHttp']
			),
            // 新增或编辑区服的分组时，使用可选择的项目
            selectGroups() {
                return this.serverGroups.slice(1)
            },
            // 可选公告类型
            selectNtypes() {
                return this.ntypes.slice(1)
            },            
		},
		methods: {
			// 刷新列表
			refreshNoticeList() {
				this.gameHttp(3071, {groupid:this.curGroupId, ntype:this.curNType}).then((response) => {
					this.paramList = response.data.list
				})
			},
            // 刷新分组列表
            refreshGroupList() {
                this.gameHttp(2006, {plain: 1}).then((response) => {
                    let list = response.data.list
                    this.serverGroups = [{value: -1,label: "全部区服列表"}]
                    for (let i=0; i<list.length; i++) {
                        this.serverGroups.push( {value:list[i].id, label:list[i].name} )
                    }
                })
            },
			// 弹出新增参数编辑框
			onClickAdd() {
                this.formTitle = '添加新通知'
				this.dialogForm = true
                this.noneChanged = true
                for (let k in this.form) {
                    this.form[k] = ''
                }
                this.form.serverid = [];
                this.formServers = []
			},
            // 弹出修改参数编辑框
            onClickEditNotice(row) {
                this.formTitle = '修改通知'
				this.dialogForm = true
                this.noneChanged = true 
                this.form = JSON.parse(JSON.stringify(row))
                this.form.notice_time = [new Date(row.begin_time), new Date(row.end_time)]
                // 加载区服列表
                this.gameHttp(2009, { // 拉取服务器列表
                    listid: this.form.groupid
                }).then((response) => {
                    this.formServers = response.data.list
                })
            },
            // 弹出删除确认框
            onClickDelNotice(row) {
                this.$confirm('是否删除[ '+row.id+' ]通知?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.gameHttp(3073, {
                        id: row.id
                    }).then((response) => {
                        if (0 == response.data.errcode) {
                            this.$message.success("删除成功")
                            this.refreshNoticeList()
                        }
                    })
                }).catch(() => {})
            },    
            // 表单分组选择变化
            onFormSelectGroup(itemid) {
                this.noneChanged = false
                this.gameHttp(2009, { // 拉取服务器列表
                    listid: itemid,
                }).then((response) => {
                    this.formServers = response.data.list
                })
            },
            onFormSelectServer() {
                this.noneChanged = false
            },
            // 编辑框内容变化事件
            onFormEdited() {
                this.noneChanged = false
            },           
			// 添加在线配置
			onAddNotice() {
				if (!this.form.content) {
                    this.$message.error('通知内容不能为空'); return;
                }
				if (!this.form.notice_time) {
                    this.$message.error('通知时间范围未设置'); return;
                }
                let interval = Number(this.form.interval);
				if (interval<0 || !interval) {
                    this.$message.error('间隔时间不正确'); return;
                }  
                let ntype = Number(this.form.type);
				if (ntype<0 || !ntype) {
                    this.$message.error('输入类型>=1'); return;
                }
                let priority = Number(this.form.priority);
				if (priority<0 || !priority) {
                    this.$message.error('输入优先级>=1'); return;
                }
                let groupid = this.form.groupid ? this.form.groupid : -1;
                this.dialogForm = false
                let notice = {
                    id: this.form.id,
                    groupid: groupid,
                    serverids: this.form.serverid,
                    interval: interval,
                    type: ntype,
                    priority: priority,
                    content: this.form.content,
                    begin_time: tool.dateFormat( this.form.notice_time[0] ),
                    end_time: tool.dateFormat( this.form.notice_time[1] ),
                };
                console.log(notice)                
                // 请求操作
                this.gameHttp(3072, notice).then((response) => {
                    if (0 == response.data.errcode) {
                        this.refreshNoticeList()
                        this.$message.success(response.data.errmsg)
                    } else {
                        this.$message.error(response.data.errmsg)
                    }
                })
			},
            // 选择服务器分组
            onSelectGroup(item) {
                this.curGroupLabel = item.label
                this.curGroupId = item.value
                // 刷新选择结果
                this.refreshNoticeList()
            }, 
            // 选择公告类型查看
            onSelectNtype() {
                this.refreshNoticeList()
            },
			// 数据表色彩间隔效果     
			tableRowClassName({
				rowIndex
			}) {
				if (0 == rowIndex % 2) {
					return 'warning-row';
				} else {
					return 'success-row';
				}
			},
		},
	}
</script>

<style>
	.serverlist.el-table .warning-row {
		background: #f5f7fa;
	}

	.serverlist.el-table .success-row {
		background: #ffffff;
	}

	.serverlist.el-table {
		margin-top: 16px;
	}

	.serverlist {
		font-size: 12px;
	} 
</style>
